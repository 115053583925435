<template>
  <v-row justify="center">
    <v-dialog v-model="shower" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card>
        <v-toolbar :color="color.theme" :dark="color.darkTheme" :text-color="color.chipText">
          <v-btn icon dark @click="$emit('closedialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>ตั้งค่าหัวข้อ</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="$emit('selectheaderbeforedownload', select)">
              <v-icon>mdi-file-export</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-overlay :value="processloader" absolute dark>
          <v-progress-circular indeterminate size="64" color="#263238"></v-progress-circular>
          <br />
          <span style="color:#263238">&nbsp; &nbsp; loading</span>
        </v-overlay>
        <v-card-text class="pa-0" style="height: 100vh">
          <v-container fluid>
            <v-layout row wrap align-center justify-center>
              <v-flex lg6 xs6 class="pa-1 pl-4">
                <v-card elevation="0" outlined class="pl-4">
                  <v-card-title>สร้าง template</v-card-title>
                  <v-card-text class="mb-2" style="height:350px">
                    <v-layout>
                      <v-flex lg6 xs6>
                        <v-text-field outlined dense @click="fn_getheader()" v-model="nametamplate"></v-text-field>
                      </v-flex>
                      <v-flex lg6 xs6 class="pl-2">
                        <v-btn :color="color.theme" :dark="color.darkTheme" @click="fn_addtemplate()">เพิ่ม</v-btn>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex>
                        <v-list style="max-height: 250px" class="overflow-y-auto">
                          <v-list-item-group color="primary">
                            <v-list-item v-for="(item, i) in tamplate" :key="i" class="overflow-y-auto">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item.template_name"
                                  @click="fn_get_listtamplate(item)"
                                ></v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-flex>
                                  <v-icon large color="primary" @click="fn_edit_tamplate(item, item.template_name)"
                                    >mdi-pencil</v-icon
                                  >
                                  <v-icon large color="red" @click="fn_delete_tamplate(item)">mdi-delete-forever</v-icon>
                                </v-flex>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <!-- <v-list style="max-height: 400px" class="overflow-y-auto">
                      <template v-for="item in tamplate">
                        <v-list-tile :key="item.template_name" avatar >
                          <v-list-tile-content>
                            <v-list-item-title v-text="item.template_name" @click="fn_get_listtamplate(item)"></v-list-item-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>
                    </v-list> -->
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                <br />
              </v-flex>
              <!-- {{feature}} -->
              <v-flex lg6 xs6 class="pr-4">
                <v-card elevation="0" outlined class="pr-4">
                  <v-card-title>เลือกหัวข้อ</v-card-title>
                  <v-card-text class="mb-2" style="height:350px">
                    <v-row style="height:350px" no-gutters dense class="overflow-y-auto">
                      <v-col v-for="item in feature" :key="item.header_name" cols="6" sm="6">
                        <v-checkbox v-model="item.select" :label="item.header_name_th" :value="item.select"></v-checkbox>
                      </v-col>
                    </v-row>
                    <!-- <v-list style="max-height: 400px" class="overflow-y-auto">
                      <template v-for="item in feature">
                        <v-list-tile :key="item.header_name" avatar >
                          <v-list-tile-content>
                           <v-checkbox v-model="item.select" :label="item.header_name" :value="item.select"></v-checkbox>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>
                    </v-list> -->

                    <!-- <v-virtual-scroll :items="feature" :item-height="50" height="400">
                      <template v-slot:default="{ item }">
                        <v-row style="height:400px" no-gutters dense>
                          <v-col :key="item.header_name" cols="6" sm="4">
                            <v-checkbox v-model="item.select" :label="item.header_name" :value="item.select"></v-checkbox>
                          </v-col>
                        </v-row>
                      </template>
                    </v-virtual-scroll> -->
                  </v-card-text>
                </v-card>
                <br />
              </v-flex>
            </v-layout>

            <!-- ตาราง -->
            <div>
              <v-card-text class="mb-2">
                ตัวอย่างตาราง
              </v-card-text>
              <v-layout>
                <v-flex>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" v-for="(items, i) in select" :key="i">
                            <div class="pl-0" v-if="items.select">{{ items.header_name }}</div>
                          </th>
                        </tr>
                      </thead>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show"],
  data: function() {
    return {
      // processloader : false,
      panel: [0],
      feature: [],
      select: [],
      listheader: [],
      benched: 0,
      nametamplate: "",
      tamplate: [],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageMax",
      "dataStorageUsage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.totalfile;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },

    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    shower: {
      get() {
        if (this.show === true) {
          console.log("เปิด dialog");
          this.fn_getheader();
          this.fn_gettamplate();
        }
        return this.show;
      },
    },
  },
  watch: {
    select() {
      if (this.select.select === "null") {
        this.deleteheader();
      }
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
    subStr_: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 60) return string.substring(0, 60) + "...";
        else return string;
      }
    },
  },
  methods: {
    async fn_getheader() {
      var payload = {};
      this.select = [];
      // let auth = await gbfGenerate.generateToken();
      this.axios
        .get(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_header_index", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            this.feature = [];
            for (let i = 0; i < response.data.result.length; i++) {
              let dataheader = {};
              dataheader["header_name"] = response.data.result[i];
              dataheader["header_name_th"] = response.data.result[i] + " " + this.fn_check_thailang(response.data.result[i]);
              // dataheader["value"] = response.data.result[i];
              dataheader["select"] = true;
              this.feature.push(dataheader);
              this.select.push(dataheader);
            }

            // this.select = response.data.result;

            this.loadprogress = false;
            console.log("feature", this.feature);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    fn_exportcsv() {
      this.listheader = [];
      for (let i = 0; i < this.feature.length; i++) {
        if (this.feature[i].select === true) {
          this.listheader.push(this.feature[i].header_name);
        }
      }
      console.log("this.listheader", this.listheader);
    },
    fn_check_thailang(item) {
      // console.log("item",item);
      let status_th;
      if (item === "XmlFlieName") {
        status_th = "(ชื่อไฟล์ Excel)";
      } else if (item === "PdfFileNAme") {
        status_th = "(ชื่อไฟล์ Excel)";
      } else if (item === "SalerName") {
        status_th = "(ชื่อผู้ขาย)";
      } else if (item === "SalerTaxID") {
        status_th = "(เลขประจำตัวผู้เสียภาษีผู้ขาย)";
      } else if (item === "SalerBranchID") {
        status_th = "(เลขที่สาขาผู้ขาย)";
      } else if (item === "DocumentTypeName") {
        status_th = "(ประเภทเอกสาร)";
      } else if (item === "DocumentTypeCode") {
        status_th = "(รหัสประเภทเอกสาร)";
      } else if (item === "Document Number") {
        status_th = "(เลขที่เอกสาร)";
      } else if (item === "Document Date") {
        status_th = "(วันที่เอกสาร)";
      } else if (item === "CustomerName") {
        status_th = "";
      } else if (item === "cust_taxid") {
        status_th = "";
      } else if (item === "company_branch") {
        status_th = "";
      } else if (item === "ItemGrossAmount") {
        status_th = "";
      } else if (item === "Discount") {
        status_th = "";
      } else if (item === "ExtraCharge") {
        status_th = "";
      } else if (item === "VatRate") {
        status_th = "";
      } else if (item === "VatAmount") {
        status_th = "";
      } else if (item === "NetAmounr") {
        status_th = "";
      } else if (item === "CustomerCode") {
        status_th = "";
      } else if (item === "salesman") {
        status_th = "";
      } else if (item === "termdesc") {
        status_th = "";
      } else if (item === "ItemNo") {
        status_th = "";
      } else if (item === "ItemCode") {
        status_th = "";
      } else if (item === "ItemName") {
        status_th = "";
      } else if (item === "Qty") {
        status_th = "";
      } else if (item === "Unit") {
        status_th = "";
      } else if (item === "Price") {
        status_th = "";
      } else if (item === "Amount") {
        status_th = "";
      } else {
        status_th = "";
      }
      return status_th;
    },
    fn_addtemplate() {
      let listselected = [];
      if (this.nametamplate === "") {
        Toast.fire({
          icon: "error",
          title: "กรุณากรอกชื่อ tamplate ที่ต้องการเพิ่ม",
        });
      } else {
        for (let i = 0; i < this.select.length; i++) {
          if (this.select[i].select === true) {
            listselected.push(this.select[i].header_name);
          }
        }
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          business_id: this.dataAccountActive.business_info.business_id,
          template_name: this.nametamplate,
          template_type: "1",
          template_detail: listselected,
        };
        console.log("payload fn_addtemplate", payload);
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/insert_column_index_template", payload)
          .then((response) => {
            if (response.data.status === "OK") {
              this.fn_gettamplate();
              this.loadprogress = false;
              this.nametamplate = "";
              Toast.fire({
                icon: "success",
                title: " Add tamplate success",
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorCode + ": " + response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      }
    },
    fn_gettamplate() {
      this.tamplate = [];
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        business_id: this.dataAccountActive.business_info.business_id,
        template_type: "1",
      };
      console.log("payload fn_gettamplate", payload);
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_column_index_template", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            for (let i = 0; i < response.data.result.length; i++) {
              let datatamplate = {};
              datatamplate["tamplate_account_id"] = response.data.result[i].account_id;
              datatamplate["tamplate_business_id"] = response.data.result[i].business_id;
              datatamplate["tamplate_cre_dtm"] = response.data.result[i].cre_dtm;
              datatamplate["tamplate_id"] = response.data.result[i].id;
              datatamplate["template_detail"] = response.data.result[i].template_detail;
              datatamplate["template_name"] = response.data.result[i].template_name;
              datatamplate["template_type"] = response.data.result[i].template_type;
              datatamplate["tamplate_update_dtm"] = response.data.result[i].update_dtm;

              this.tamplate.push(datatamplate);
            }
            this.loadprogress = false;
            console.log("tamplate", this.tamplate);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    fn_delete_tamplate(item) {
      console.log("item+++", item);
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        business_id: this.dataAccountActive.business_info.business_id,
        template_id: item.tamplate_id,
      };
      console.log("payload", payload);
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_column_index_template", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            this.fn_gettamplate();
            this.loadprogress = false;
            Toast.fire({
              icon: "success",
              title: " Delete tamplate success",
            });
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    fn_get_listtamplate(item) {
      console.log("====", item);
      this.select = [];
      for (let i = 0; i < item.template_detail.length; i++) {
        let dataheader = {};
        dataheader["header_name"] = item.template_detail[i];
        dataheader["header_name_th"] = item.template_detail[i] + " " + this.fn_check_thailang(item.template_detail);
        dataheader["select"] = true;
        this.select.push(dataheader);
      }
      // console.log("this.select", this.select);

      for (let i = 0; i < this.feature.length; i++) {
        let objIndex = this.select.findIndex((obj) => obj.header_name === this.feature[i].header_name);
        // console.log("objIndex", objIndex);
        if (objIndex === -1) {
          this.feature[i]["select"] = null;
        } else {
          this.feature[i]["select"] = true;
        }
      }
      console.log("this.feature++++++", this.feature);
    },
    fn_edit_tamplate(item, name) {
      let edit_listselected= [];
       let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        business_id: this.dataAccountActive.business_info.business_id,
        template_id: item.tamplate_id,
      };
      console.log("payload", payload);
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_column_index_template", payload)
        .then((response) => {
          if (response.data.status === "OK") {
             for (let i = 0; i < this.feature.length; i++) {
          if (this.feature[i].select === true) {
            edit_listselected.push(this.feature[i].header_name);
          }
        }
        console.log("edit_listselected",edit_listselected);
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          business_id: this.dataAccountActive.business_info.business_id,
          template_name: name,
          template_type: "1",
          template_detail: edit_listselected,
        };
        console.log("payload fn_addtemplate", payload);
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/insert_column_index_template", payload)
          .then((response) => {
            if (response.data.status === "OK") {
              this.fn_gettamplate();
              this.loadprogress = false;
              this.nametamplate = "";
              Toast.fire({
                icon: "success",
                title: " Edit tamplate success",
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorCode + ": " + response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
  },
};
</script>

<style></style>
